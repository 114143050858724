import getData from "@/__main__/get-data.mjs";
import ValorantWeapons from "@/data-models/valorant-weapon.mjs";
import { getValorantConstDataByType } from "@/game-val/api.mjs";

export default async function fetchData() {
  await getData(
    getValorantConstDataByType("weapons"),
    ValorantWeapons,
    ["val", "meta", "weapons"],
    { shouldFetchIfPathExists: false },
  );
}
